import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

import BlogImg1 from "../../assets/images/blog-image/b1.jpg"
import BlogImg2 from "../../assets/images/blog-image/b2.jpg"
import BlogImg3 from "../../assets/images/blog-image/b3.jpg"

const BlogPost = () => {
  return (
    <div className="">
      <div className="container">
        <div className="section-title" style={{ marginTop: "3rem" }}>
          <h2>Our Latest Blogs</h2>
          <div className="bar"></div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-blog-post">
              <div className="blog-image">
                {/* <Link to="/blog-details"> */}
                <img src={BlogImg1} alt="image" />
                {/* </Link> */}

                <div className="date">
                  <Icon.Calendar /> March 15, 2021
                </div>
              </div>

              <div className="blog-post-content">
                <h3>
                  {/* <Link to="/blog-details"> */}
                  Arrow Functions
                  {/* </Link> */}
                </h3>

                <span>{/* By <Link to="/author">Admin</Link> */}</span>

                <p>
                  An arrow function expression is a compact alternative to a
                  traditional function expression, but is limited and can't be
                  used ..........
                </p>

                {/* <Link to="#" className="read-more-btn">
                  Read More <Icon.ArrowRight />
                </Link> */}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-blog-post">
              <div className="blog-image">
                {/* <Link to="/blog-details"> */}
                <img src={BlogImg2} alt="image" />
                {/* </Link> */}

                <div className="date">
                  <Icon.Calendar /> March 21, 2021
                </div>
              </div>

              <div className="blog-post-content">
                <h3>
                  {/* <Link to="/blog-details"> */}
                  NPM v/s Yarn
                  {/* </Link> */}
                </h3>

                <span>{/* By <Link to="/author">Smith</Link> */}</span>

                <p>
                  In this tutorial, we’ll compare npm vs yarn — the two most
                  popular package managers. We’ll set them side by side and
                  explore their respectiv ..........
                </p>

                {/* <Link to="#" className="read-more-btn">
                  Read More <Icon.ArrowRight />
                </Link> */}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-blog-post">
              <div className="blog-image">
                {/* <Link to="/blog-details"> */}
                <img src={BlogImg3} alt="image" />
                {/* </Link> */}

                <div className="date">
                  <Icon.Calendar /> April 2, 2021
                </div>
              </div>

              <div className="blog-post-content">
                <h3>
                  {/* <Link to="/blog-details"> */}
                  PROS AND CONS - GATSBY JS
                  {/* </Link> */}
                </h3>

                <span>{/* By <Link to="/author">John</Link> */}</span>

                <p>
                  Companies are looking for modern solutions to problems with
                  their websites like slow page load speed, bad SEO or inability
                  to improve ..........
                </p>

                {/* <Link to="#" className="read-more-btn">
                  Read More <Icon.ArrowRight />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPost
